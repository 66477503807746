export const TENANCY_ID_KEY = "tenancyId";
export const PROPERTY_ID_KEY = "propertyId";
export const BRANDING_DATA_KEY = "brandingData";
export const USER_TENANCIES = "userTenancies";
export const NAV_HISTORY_KEY = "NAV_HISTORY";
export const DEEP_LINK = "DEEP_LINK";
export const IS_FIRST_TIME_LOGIN = "IsFirstTimeLogin";
export const SELECTED_PROPERTY_KEY = "SELECTED_PROPERTY";
export const HIDE_OFFER_BANNER = "HIDE_OFFER_BANNER";
export const BANNER_OFFER_DURATION = "BANNER_OFFER_DURATION";
